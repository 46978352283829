<template>
  <wide-panel
    :title="title"
    :breadcrumbs="breadcrumbs"
  >
    <v-toolbar card>
      <v-menu
        v-model="menu"
        close-on-content-click
        lazy
        transition="scale-transition"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="month"
          prepend-inner-icon="event"
          readonly
        />
        <v-date-picker
          v-model="month"
          :max="thisMonth"
          min="2020-01"
          type="month"
        />
      </v-menu>
      <v-select
        v-model="select"
        :items="selects"
        item-text="text"
        item-value="value"
        label="Filter By"
      />
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <v-tooltip
        v-if="items.length"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            fab
            dark
            small
            right
            color="green"
            v-on="on"
            @click="exportDealerPerformance"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      :rows-per-page-items="[10, 25, 50, 100]"
      item-key="msisdn"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.subscriptionDate }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan }}
          </td>
          <td
            v-if="props.item.tiers === 'direct'"
            class="text-xs-center"
          >
            <v-icon color="green">
              mdi-head-check-outline
            </v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
            <v-icon color="grey">
              mdi-head-outline
            </v-icon>
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.percent }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reload }}
          </td>
          <td class="text-xs-right">
            {{ props.item.incentive }}
          </td>
          <template v-if="withIncentiveCuts">
            <td class="text-xs-right">
              {{ props.item.incentiveFc }}
            </td>
            <td class="text-xs-right">
              {{ props.item.incentiveRemaining }}
            </td>
          </template>
        </tr>
      </template>
      <template slot="footer">
        <tr>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-center" />
          <td class="text-xs-center" />
          <td class="text-xs-center" />
          <td class="text-xs-center" />
          <td class="text-xs-center" />
          <td class="text-xs-center" />
          <td class="text-xs-center" />
          <td class="text-xs-right info">
            RM {{ totalreload }}
          </td>
          <td class="text-xs-right info">
            RM {{ totalincentive }}
          </td>
          <template v-if="withIncentiveCuts">
            <td class="text-xs-right info">
              RM {{ totalIncentiveFc }}
            </td>
            <td class="text-xs-right info">
              RM {{ totalIncentiveRemaining }}
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </wide-panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'
import zipcelx from 'zipcelx'

const title = 'MySiswa Special Incentives'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: title, disabled: true,
        },
      ],
      items: [],
      select: '0',
      selects: [
        {
          text: 'All',
          value: '0',
        },
        {
          text: 'Direct Channel',
          value: '1',
        },
        {
          text: 'Indirect Channel',
          value: '2',
        },
      ],
      loading: false,
      menu: false,
      month: null,
      search: '',
      title: title,
      totalincentive: 0.00,
      totalIncentiveRemaining: 0.00,
      totalIncentiveFc: 0.00,
      totalreload: 0.00,
      withIncentiveCuts: false,
    }
  },
  computed: {
    thisMonth: function () {
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      return now.toFormat('yyyy-LL')
    },
    headers: function () {
      const headers = [
        {
          text: 'Subscription Date',
          align: 'left',
          sortable: true,
          value: 'subscriptionDate',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: true,
          value: 'plan',
        },
        {
          text: 'Channel',
          align: 'left',
          sortable: true,
          value: 'tiers',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Percentage (%)',
          align: 'left',
          sortable: true,
          value: 'percent',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: true,
          value: 'reload',
        },
        {
          text: 'Incentive (RM)',
          align: 'right',
          sortable: true,
          value: 'incentive',
        },
      ]

      this.withIncentiveCuts && headers.push(
        {
          text: 'FC Cut (RM)',
          align: 'right',
          sortable: true,
          value: 'incentiveFc',
        },
        {
          text: 'Balance (RM)',
          align: 'right',
          sortable: true,
          value: 'incentiveRemaining',
        },
      )

      return headers
    },
    excelHeader: function () {
      const headers = [
        {
          value: 'Subscription Date',
          type: 'string',
        },
        {
          value: 'Msisdn',
          type: 'string',
        },
        {
          value: 'Name',
          type: 'string',
        },
        {
          value: 'Plan',
          type: 'string',
        },
        {
          value: 'Entry',
          type: 'string',
        },
        {
          value: 'Dealer',
          type: 'string',
        },
        {
          value: 'Channel',
          type: 'string',
        },
        {
          value: 'Percentage (%)',
          type: 'string',
        },
        {
          value: 'Reload',
          type: 'string',
        },
        {
          value: 'Incentive (RM)',
          type: 'string',
        },
      ]

      this.withIncentiveCuts && headers.push(
        {
          value: 'FC Cut (RM)',
          type: 'string',
        },
        {
          value: 'Remaining (RM)',
          type: 'string',
        },
      )

      return headers
    },
  },
  watch: {
    month: function (val) {
      val && this.getMySiswaIncentive(val)
    },
    select: function (val) {
      val && this.getMySiswaIncentive(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.month = now.toFormat('yyyy-LL')
  },
  methods: {
    getMySiswaIncentive: function () {
      const param = createGetParams({
        month: this.month,
        select: this.select,
      })
      this.loading = true
      this.alert = false
      this.items = []
      this.$rest.get('getMySiswaIncentive.php', param)
        .then(function (response) {
          this.items = response.data.item
          this.totalreload = response.data.totalreload
          this.totalincentive = response.data.totalincentive
          if (response.data.message) {
            this.alertMessage = response.data.message
            this.alertType = 'info'
            this.alert = true
          }
          this.loading = false
          this.withIncentiveCuts = response.data.withIncentiveCuts
          if (this.withIncentiveCuts) {
            this.totalIncentiveFc = response.data.totalIncentiveFc
            this.totalIncentiveRemaining = response.data.totalIncentiveRemaining
          }
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alertType = 'error'
          this.alert = true
          this.loading = false
        })
    },

    exportDealerPerformance: function () {
      const config = {
        filename: security.me.dealerId() + '-MySiswaSpecialIncentive-' + this.month,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.excelHeader)
      this.items.forEach(item => {
        const subs = [
          {
            value: item.subscriptionDate,
            type: 'string',
          },
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.plan,
            type: 'string',
          },
          {
            value: item.entry,
            type: 'string',
          },
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.tiers,
            type: 'string',
          },
          {
            value: item.percent,
            type: 'string',
          },
          {
            value: item.reload,
            type: 'string',
          },
          {
            value: item.incentive,
            type: 'string',
          },
        ]

        this.withIncentiveCuts && subs.push(
          {
            value: item.incentiveFc,
            type: 'string',
          },
          {
            value: item.incentiveRemaining,
            type: 'string',
          },
        )

        config.sheet.data.push(subs)
      })

      zipcelx(config)
    },
  },
}
</script>
